import * as React from 'react';
import styles from './ThemesHeroLayout.module.css';

function ThemesHeroLayoutRoot({ children, ...props }: React.ComponentPropsWithoutRef<'div'>) {
  return <div className={styles.ThemesHeroLayoutRoot} {...props}>
    <div className={styles.ThemesHeroLayoutContent}>{children}</div>
  </div>
}

function ThemesHeroLayoutBackground(props: React.ComponentPropsWithoutRef<'div'>) {
  return <div className={styles.ThemesHeroLayoutBackground} {...props} />
}

function ThemesHeroLayoutMain(props: React.ComponentPropsWithoutRef<'div'>) {
  return <div className={styles.ThemesHeroLayoutMain} {...props} />
}

function ThemesHeroLayoutControls(props: React.ComponentPropsWithoutRef<'div'>) {
  const [rendered, setRendered] = React.useState(false);

  React.useEffect(() => {
    setRendered(true);
  }, []);

  if (!rendered) {
    return null;
  }

  return <div className={styles.ThemesHeroLayoutControls} {...props} />;
}

function ThemesHeroLayoutShowcase({ children }: React.ComponentPropsWithoutRef<'div'>) {
  return (
    <div aria-hidden className={styles.ThemesHeroLayoutShowcase}>
      <div className={styles.ThemesHeroLayoutShowcaseInner}>
        {/* An extra div is needed to have padding working as expected within the scroll container */}
        <div>
          <div className={styles.ThemesHeroLayoutShowcaseInnerScaled}>{children}</div>
        </div>
      </div>
    </div>
  );
}

export const ThemesHeroLayout = {
  Root: ThemesHeroLayoutRoot,
  Background: ThemesHeroLayoutBackground,
  Main: ThemesHeroLayoutMain,
  Controls: ThemesHeroLayoutControls,
  Showcase: ThemesHeroLayoutShowcase,
};
