import { Heading } from '@scale/scaleui-radix';

export function InterHeading(props: React.ComponentPropsWithRef<typeof Heading>) {
  return (
    <Heading
      size={{ initial: '8', xs: '9' }}
      weight="regular"
      {...props}
      style={
        {
          '--heading-font-family': 'var(--default-font-family)',
          '--heading-font-size-adjust': '1.1',
          ...props.style,
        } as React.CSSProperties
      }
    />
  );
}
